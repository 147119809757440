<template>
  <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">
    <div class="tw-pt-4 sm:tw-pt-24 tw-text-center">
            <v-alert
              v-if="error"
              color="orange"
              outlined
              shaped
              type="info"
              >
              {{error.message}}
            </v-alert>

      <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-2">{{ $t('auth.login') }} </h1>
      <p class="tw-text-sm tw-text-gray-n3 tw-mb-0">{{ $t('auth.welcome_back') }}</p>
    </div>

      <div v-if="mustVerifyEmail" class="tw-pt-24 tw-text-center">
        <v-alert outlined prominent border="left"  type="success">
          <div class="title">{{ $t('auth.verify_account') }}</div>
              <div v-if="this.data">{{ $t('auth.verify_account_info1') }} {{this.data.email}}. {{ $t('auth.verify_account_info2') }}</div>
              <p>{{this.data.status}}</p>
        </v-alert>
      </div>

    <form class="sm:tw-w-2/5 tw-px-4 tw-mx-auto tw-text-left tw-mt-6" @submit.prevent="onSubmit" method="POST">
    <ml-error-msg :msg="errors['error']" />
      <div>
        <label for="email" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
          Email address <span class="tw-text-red">*</span>
        </label>
        <div class="tw-mt-2">
          <input v-model="form.email" v-focus id="email" name="email" type="email" autocomplete="email" required class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-border tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
          <ml-error-msg :msg="errors['email']" />
        </div>
      </div>

      <div class="tw-mt-6">
        <label for="password" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
          Password <span class="tw-text-red">*</span>
        </label>
        <div class="tw-mt-2 tw-relative tw-rounded-xl tw-border tw-border-gray-n1">
          <input v-model="form.password" id="password" name="password" :type="showPasswordValue ? 'text' : 'password'" autocomplete="current-password" required class="tw-appearance-none tw-bg-transparent tw-block tw-w-full tw-px-4 tw-py-3 tw-border-none tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
          <a class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center" @click.prevent.stop="togglePassword" @mouseleave="hidePassword">
            <eye-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </a>
        </div>
        <ml-error-msg :msg="errors['password']" />
      </div>

      <div class="tw-text-xs tw-mt-1">
        <router-link :to="{ name: 'password.request' }" class="tw-text-gray-n4">
          Forgot your password?
        </router-link>
      </div>

      <div class="tw-flex tw-items-center tw-justify-between tw-mt-4">
        <div class="tw-flex tw-items-center">
          <input id="remember-me" name="remember-me" type="checkbox" class="tw-h-4 tw-w-4 tw-text-gray-n4 focus:tw-ring-gray-n4 tw-border tw-border-solid tw-border-gray-n1 tw-rounded">
          <label for="remember-me" class="tw-ml-2 tw-block tw-text-sm tw-text-gray-900 tw-mb-0">
            Remember me
          </label>
        </div>
      </div>
      <button type="submit" class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-shadow-sm tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
        {{ $t('auth.login') }}
      </button>
    </form>

  </div>
</template>

<script>
import Form from 'vform'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import i18n from '@/i18n'
import { EyeIcon } from 'vue-feather-icons'
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";

export default {
  middleware: 'guest',
  props: ['back'],
  components: {
    EyeIcon,
    MlErrorMsg
  },
  metaInfo() {
    return {title: this.$t('auth.login')}
  },
  data: () => ({
    appName: process.env.VUE_APP_TITLE ? process.env.VUE_APP_TITLE : '...',
    valid: true,
    lazy: true,
    showPasswordValue: false,
    data: [],
    errors: [],
    error: null,
    form: new Form({
      email: '',
      password: ''
    }),
    showPassword: false,
    remember: false,
    mustVerifyEmail: false,
    rules: {
      required: value => !!value || i18n.t('auth.required'),
      min: v => v.length >= 8 || i18n.t('auth.required_min'),
    },
    emailRules: [
      v => !!v || i18n.t('auth.email_required'),
      v => /.+@.+/.test(v) || i18n.t('auth.email_valid'),
    ],
  }),
  computed: {
    ...mapGetters({
      locales: 'lang/locales',
    }),

  },
  mounted(){
    this.loginAs(this.$route.query.token);
  },
  methods: {
    async loginAs(token){
      if(token){
        await this.$store.dispatch('auth/saveToken', { token });
        await this.$store.dispatch('auth/fetchUser');
        this.$router.push({ name: 'welcome' });
      }
    },
    checkForm: function () {
      this.errors = [];
      let label = '';

      if (!this.form.email) {
        label = "Email required.";
        this.errors["email"] = label;
        this.errors.push(label);
      } else if (!this.validEmail(this.form.email)) {
        label = "Valid email required.";
        this.errors["email"] = label;
        this.errors.push(label);
      }

      if (!this.form.password) {
        label = i18n.t('auth.required_password');
        this.errors["password"] = label;
        this.errors.push(label);
      }else{
        if(this.form.password.length < 8){
          label = i18n.t('auth.password_min');
          this.errors["password"] = label;
          this.errors.push(label);
        }
      }

      if (!this.errors.length) {
        this.valid = true;
        return true;
      }
      this.valid = false;
      return false;
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    togglePassword() {
      this.showPasswordValue = !this.showPasswordValue;
    },
    hidePassword() {
      this.showPasswordValue = false;
    },
    onSubmit() {
      if(this.checkForm()) this.login();
    },
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    async login() {
      // TODO: clear login info!!!
      // await this.$refs.form.validate();
      if (!this.valid) return;
      // Submit the form.
      try {
        await this.form.get('sanctum/csrf-cookie')
        const {data} = await this.form.post('api/login')
        this.data = data;
        //const { data } = await this.signIn(this.form)

        // Must verify email fist.
        if (data.status) {
          this.mustVerifyEmail = true
        }else if (data.activation) {
          this.error = data;
        }else {
          // Save the token.
          await this.$store.dispatch('auth/saveToken', {
            token: data.token,
            remember: this.remember
          })

          // Fetch the user.
          await this.$store.dispatch('auth/fetchUser')
          //await this.$store.dispatch('budget/fetchBudget');
          // Redirect home.
          this.$router.push(this.$route.query.redirectedFrom || '/')
          // if (!this.$route.query.back && !this.back) {
          //   this.$router.go(-1);
          //   this.$router.push({name: 'feed'});
          // }
          this.$emit("signed", 1);

        }
      } catch (error) {
        this.error = error.data;
        this.errors["error"] = "An error has occurred";
      }
    }
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    }
  }
}
</script>
